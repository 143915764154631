<template>
  <v-card v-if="summary">
    <loading-bar v-model="summary.loading"></loading-bar>
    <v-window v-model="window" touchless>
      <!-- TEAMS -->
      <v-window-item :key="0">
        <v-card-text>
          <v-container fluid>
            <v-row dense>
              <v-col cols="12" sm="6" md="4" v-for="p in summary.parents" :key="p.id">
                <v-card class="fill-height flexcard">
                  <v-card-title>
                    <v-avatar
                      size="48"
                      tile
                    >
                      <v-img :src="p.logoUrl" contain></v-img>
                    </v-avatar>
                    <div class="pl-2">
                      <div><fit-text>{{p.name}}</fit-text></div>
                      <div class="caption grey--text">{{p.owner}}</div>
                    </div>
                  </v-card-title>
                  <v-divider></v-divider>
                  <v-card-text v-if="p.squads.length > 0 && false">
                    <div>Squads</div>
                  </v-card-text>
                  <v-card-text v-if="p.pairs.teams.length > 0">
                    <v-row dense class="text-h6">
                      <v-col cols="6">Pairs</v-col>
                      <v-col cols="6" class="text-end">
                        <div>{{p.pairs.record.w}}-{{p.pairs.record.l}}</div>
                        <div v-if="p.pairs.unplayed" class="caption grey--text">{{p.pairs.unplayed}} remaining</div>
                      </v-col>
                    </v-row>
                    <v-row dense v-for="pair in p.pairs.teams" :key="pair.name">
                      <v-col cols="10">{{pair.name}}</v-col>
                      <v-col cols="2" class="text-end"><b>{{pair.record.w}}-{{pair.record.l}}</b></v-col>
                    </v-row>
                  </v-card-text>
                  <v-card-actions class="pb-0 grow align-end justify-end" v-if="false">
                    <v-btn
                      color="color3"
                      @click.stop="selectTeam(p)"
                      small text
                    >
                      more <v-icon class="ml-3">fas fa-caret-right</v-icon>
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-window-item>
      <!-- DETAILS -->
      <v-window-item :key="1">
        <v-card-text v-if="selected" class="pt-0 px-0">
          <v-container fluid>
            <v-row dense>
              <v-col cols="12">
                <v-fab-transition>
                  <v-btn
                    color="color3 color3Text--text"
                    fab
                    fixed bottom
                    @click.stop="backClick"
                  >
                    <v-icon>fas fa-caret-left</v-icon>
                  </v-btn>
                </v-fab-transition>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12">
                <v-card>
                  <v-card-title>
                    <v-avatar
                      size="48"
                      tile
                    >
                      <v-img :src="selected.logoUrl" contain></v-img>
                    </v-avatar>
                    <div class="pl-2">
                      <div>{{selected.name}}</div>
                      <div class="caption grey--text">{{selected.owner}}</div>
                    </div>
                  </v-card-title>
                  <v-divider></v-divider>
                  <v-card-text v-if="selected.pairs.teams.length > 0" class="pa-1 grey lighten-3">
                    <v-row dense class="text-h6">
                      <v-col cols="6">Pairs</v-col>
                      <v-col cols="6" class="text-end">
                        <div>{{selected.pairs.record.w.length}}-{{selected.pairs.record.l.length}}</div>
                        <div v-if="selected.pairs.unplayed.length" class="caption grey--text">{{selected.pairs.unplayed.length}} remaining</div>
                      </v-col>
                    </v-row>
                    <v-row dense v-for="pair in selected.pairs.teams" :key="pair.team.id">
                      <v-col cols="12">
                        <v-card class="pa-0">
                          <v-card-text class="title px-2 py-1">
                            <v-row dense class="text-h6">
                              <v-col cols="6">{{pair.name}}</v-col>
                              <v-col cols="6" class="text-end">
                                <div>{{pair.record.w.length}}-{{pair.record.l.length}}</div>
                                <div v-if="pair.unplayed.length" class="caption grey--text">{{pair.unplayed.length}} remaining</div>
                              </v-col>
                            </v-row>
                          </v-card-text>
                          <v-divider></v-divider>
                          <v-expand-transition>
                            <v-card-text v-if="pair.matches.length > 0" class="pa-0">
                              <v-container fluid class="pa-2">
                                <v-row dense>
                                  <v-col cols="6" md="3" lg="2" v-for="match in pair.matches" :key="match.id">
                                    <match-card
                                      :match="match"
                                      :divisionId="pair.division.id"
                                    ></match-card>
                                  </v-col>
                                </v-row>
                              </v-container>
                            </v-card-text>
                          </v-expand-transition>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-window-item>
    </v-window>
  </v-card>
</template>

<script>
import TournamentSummarizer from '@/classes/TournamentSummarizer.js'
import MatchCard from '@/components/Tournament/Match/NoDetailsMatchCard'

export default {
  props: ['tournament'],
  data () {
    return {
      summary: null,
      window: 0,
      selected: null,
      refreshTimer: null
    }
  },
  methods: {
    selectTeam (team) {
      this.selected = team
      this.window = 1
      this.setQ()
    },
    selectQTeam () {
      if (this.$route.query.id) {
        const team = this.summary.parents.find(f => f.id === +this.$route.query.id)
        if (team) this.selectTeam(team)
      }
    },
    setQ () {
      const query = {}
      Object.assign(query, this.$route.query)
      if (this.selected) {
        query.id = this.selected.id
      } else if ('id' in query) {
        delete query.id
      }
      this.$router.push({ query })
    },
    backClick () {
      this.window = 0
      this.selected = null
      this.setQ()
    },
    refresh () {
      this.summary.recalc()
      if (this.tournament.locked) {
        console.log('locked no refresh timer')
      } else {
        this.refreshTimer = setTimeout(this.refresh, 5000)
      }
    }
  },
  watch: {
    'tournament.matches.length': function (v) {
      this.refresh()
    }
  },
  components: {
    MatchCard
  },
  mounted () {
    this.summary = new TournamentSummarizer(this.tournament, this.$VBL)
    this.refresh()
  },
  destroyed () {
    if (this.refreshTimer) clearTimeout(this.refreshTimer)
  }
}
</script>
